<template>
  <div class="first">
    <div v-if="isshow=='1'">
      <img class="logo"
           src="@/assets/img/logo-alt.png"
           alt="">
      <div class="title">凤凰汇会务小程序</div>
      <img class="slogn"
           src="@/assets/img/slogn.png"
           alt="">
      <div class="subtitle">提供一站式线上线下融合活动管理服务。集活动品牌树立、参会人员管理、智慧通知、活动直播及私有化定制等特色功能于一身，助力企业办会更轻松。</div>
      <img class="btn"
           src="@/assets/img/lxwm.png"
           alt=""
           @click="openDialog">
    </div>
    <div v-if="isshow=='2'">
      <div class="bg-blue">
        <div class="title">立即创建您的活动</div>
        <div class="subtitle">多样高效的功能模块，助您轻松办好会</div>
        <img class="btn"
             src="@/assets/img/lxwm.png"
             alt=""
             @click="openDialog">
      </div>
      <div class="bottom">
        <img class="logo-1"
             src="../assets/img/logo-alt.png"
             alt="">
        <img class="slogn-2"
             src="../assets/img/slogn-2.png"
             alt="">
      </div>
      <div class="copyright">Copyright © 2023 苏州凤凰汇创意文化传播有限公司 <br />
        版权所有 苏ICP备12004061号-3</div>
    </div>
    <div class="dialog"
         v-show="isDlg"
         @click="closeDialog">
      <img src="../assets/img/dialog.png"
           alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'first',
  props: {
    isshow: String
  },
  data () {
    return {
      isDlg: false
    }
  },
  methods: {
    openDialog () {
      this.preventScoll(true)
      this.hideOrShowDialog()
    },
    closeDialog () {
      this.preventScoll(false)
      this.hideOrShowDialog()
    },
    hideOrShowDialog () {
      this.isDlg = !this.isDlg
    },
    preventScoll (flag) {
      if (flag) {
        const top = document.documentElement.scrollTop || document.body.scrollTop;
        document.body.style.cssText += `
            position: fixed;
            width: 100vw;
            left: 0;
            top: ${-top}px;
        `
      } else {
        const top = document.body.style.top;
        document.body.style.cssText += `
            position: static;
        `
        window.scrollTo(0, Math.abs(parseFloat(top)))
      }
    }

  }
}
</script>

<style scoped lang="less">
.first {
  background: #f7faff;
  width: 100%;
  // min-height: 626px;
  .logo {
    margin-top: 80px;
    width: 104px;
    height: 55px;
  }
  .title {
    font-size: 34px;
    // font-weight: 500;
  }
  .subtitle {
    font-size: 17px;
    padding: 53px 50px;
    font-weight: lighter;
    font-weight: normal;
    line-height: normal;
  }
  .slogn {
    margin-top: 17px;
    width: 222px;
    height: 50px;
  }
  .btn {
    width: 160px;
    height: 50px;
    margin-bottom: 53px;
  }
  .bg-blue {
    background: #eaf3fb;
    .title {
      font-size: 21px;
      font-weight: bold;
      padding-top: 37px;
    }
    .subtitle {
      font-size: 15px;
      color: #4e5969;
      padding: 13px 0;
    }
    .btn {
      margin: 32px auto;
    }
  }
  .bottom {
    background: #f7faff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 34px 0;
    border-bottom: 0.5px solid #bfbfbf;
    .logo-1 {
      width: 105px;
      height: 58px;
    }
    .slogn-2 {
      width: 183px;
      height: 42px;
    }
  }
  .copyright {
    background: #f7faff;
    font-size: 12px;
    color: #888;
    padding: 29px 0;
  }
}
.dialog {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  transition: 0.5s;
  background: rgba(0, 0, 0, 0.8);
  img {
    width: 328px;
    height: 410px;
    border-radius: 20px;
  }
}
</style>