<template>
  <div class="three">
    <div class="title-box">
      <div class="title">适用「多种场景」<br />带你体验不同精彩活动</div>
      <div class="subtitle">无论是你是50人小型研讨会，<br />
        或是500人会议峰会，<br />
        凤凰汇都能满足您的需求。</div>
    </div>
    <div class="list-box">
      <!-- {{item.img}} -->
      <div class="list"
           v-for="item in listArr">
        <img :src="item.img"
             alt="">
        <div class="title">{{ item.title }}</div>
        <div class="subtitle">{{ item.subtitle }}</div>
      </div>
      <!-- <div class="list"></div>
      <div class="list"></div>
      <div class="list"></div>
      <div class="list"></div>
      <div class="list"></div>
      <div class="list"></div>
      <div class="list"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      listArr: [{ img: require('../assets/img/list-1.png'), title: '政务会议', subtitle: '快速响应高效办会' },
      { img: require('../assets/img/list-2.png'), title: '新品发布会', subtitle: '在线报名高清直播' },
      { img: require('../assets/img/list-3.png'), title: '商业路演', subtitle: '完备的商业路演方案' },
      { img: require('../assets/img/list-4.png'), title: '公司年会', subtitle: '多样互动环节' },
      { img: require('../assets/img/list-5.png'), title: '活动峰会', subtitle: '多个分会场多路直播' },
      { img: require('../assets/img/list-6.png'), title: '研讨会', subtitle: '小规模在线研讨会' },
      { img: require('../assets/img/list-7.png'), title: '博览会', subtitle: '展位内容丰富展示' },
      { img: require('../assets/img/list-8.png'), title: '时尚走秀', subtitle: '多角度观看时尚舞台' }]
    }
  }
}
</script>

<style lang="less" scoped>
.three {
  background: #f7faff;
  .title-box {
    padding-top: 81px;
    .title {
      font-size: 27px;
      font-weight: 500;
      line-height: 37px;
    }
    .subtitle {
      color: #888;
      font-size: 17px;
      padding: 0 17px;
      padding-top: 27px;
      text-align: center;
    }
  }
  .list-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 63px 37px;
    .list {
      width: 150px;
      height: 160px;
      background: #fff;
      margin-top: 15px;
      border-radius: 10px;
      box-shadow: 0px 6px 12px 0px rgba(22, 93, 255, 0.05);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:nth-child(1),
      &:nth-child(2) {
        margin-top: 0;
      }
      img {
        width: 43px;
        height: 43px;
        margin-bottom: 20px;
      }
      .title {
        font-size: 20px;
        font-weight: bold;
      }
      .subtitle {
        padding-top: 8px;
        color: #888;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}
</style>