<template>
  <div class="four">
    <img class="bg"
         src="../assets/img/oval.png"
         alt="">
    <div class="title-box">
      <div class="title">看看不同「皮肤」的
        <br />会务小程序</div>
    </div>

    <div class="scroll-content">
      <div class="scroll"><img src="../assets/img/1.png"
             alt=""
             srcset=""></div>
      <div class="scroll"><img src="../assets/img/2.png"
             alt=""
             srcset=""></div>
      <div class="scroll"><img src="../assets/img/3.png"
             alt=""
             srcset=""></div>
      <div class="scroll"><img src="../assets/img/4.png"
             alt=""
             srcset=""></div>
      <div class="scroll"><img src="../assets/img/5.png"
             alt=""
             srcset=""></div>
      <div class="scroll"><img src="../assets/img/6.png"
             alt=""
             srcset=""></div>
      <div class="scroll"><img src="../assets/img/7.png"
             alt=""
             srcset=""></div>
      <div class="scroll"><img src="../assets/img/8.png"
             alt=""
             srcset=""></div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.four {
  position: relative;
  background: #fff;
  min-height: 600px;
  z-index: -999;
  .bg {
    position: absolute;
    width: 335px;
    height: 335px;
    top: 18px;
    left: -48px;
    z-index: -1;
  }
  .title-box {
    padding-top: 81px;
    .title {
      font-size: 27px;
      font-weight: 500;
      line-height: 37px;
    }
  }
  .scroll-content {
    position: relative;
    display: flex;
    width: 100vw;
    height: 720px;
    box-sizing: border-box;
    overflow-x: auto;
    .scroll {
      width: 300px;
      height: 600px;
      margin: 60px 7.5px;
      flex-shrink: 0;
      border-radius: 10px;
      display: inline-flexbox;
      &:first-child {
        margin-left: 28px;
      }
      &:last-child {
        margin-right: 28px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>