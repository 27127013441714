<template>
  <div class="home">
    <first isshow="1"></first>
    <two></two>
    <three></three>
    <four></four>
    <first isshow="2"></first>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import first from '@/components/first.vue'
import two from '@/components/two.vue'
import three from '@/components/three.vue'
import four from '@/components/four.vue'
export default {
  name: 'Home',
  components: {
    first,
    two,
    three,
    four
  }
}
</script>
<style lang="less" scoped>
.home {
  position: absolute;
  z-index: -999;
}
</style>
