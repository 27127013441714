<template>
  <div class="two">
    <img class="bg"
         src="../assets/img/oval.png"
         alt="">
    <div class="title-box">
      <div class="title">「多样功能模块」<br />让会议活动大展拳脚</div>
      <div class="title"></div>
      <div class="subtitle">主办方品牌树立、智慧通知提醒、定制化日程等多样功能模块，体验更轻松。</div>
    </div>
    <img class="alt1"
         src="../assets/img/alt1.png"
         alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.two {
  position: relative;
  background: #fff;
  z-index: -999;
  .bg {
    position: absolute;
    width: 335px;
    height: 335px;
    top: 18px;
    left: -48px;
    z-index: -1;
  }
  .title-box {
    padding-top: 81px;
    .title {
      font-size: 27px;
      font-weight: 500;
      line-height: 37px;
    }
    .subtitle {
      color: #888;
      font-size: 17px;
      padding: 0 17px;
      padding-top: 27px;
      text-align: center;
    }
  }
  .alt1 {
    width: 100%;
  }
}
</style>